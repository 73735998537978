import { apiClient } from '../../common/axios_instance'; 
import moment from 'moment';


export async function getAllProjectsBasic(params) {
    try {
        let projectsResponse = await apiClient.get(`${process.env.REACT_APP_MITIGATION_API_ENDPOINT}/api/projects${params ? `?${params}` : ``}`);
    
        // dont set the ALL_PROJECTS action here as it would return only a subset of data
        return projectsResponse.data || [];
    }
    catch(e) {
        console.error('Cannot get projects info', e);
    }
}

export function getAllProjects(callback) {
    return async function (dispatch, getState) {
        // Gets all projects
        // Currently no group filtering is set up for projects interface.

        let projectsResponse = await apiClient.get(process.env.REACT_APP_MITIGATION_API_ENDPOINT + '/api/projects');

        callback && callback(projectsResponse.data || [])
        dispatch({ type: 'ALL_PROJECTS', payload: projectsResponse.data || [] });
    }
}

export function clearProject(callback) {
    return async function (dispatch, getState) {
        dispatch({ type: 'CURRENT_PROJECT', payload: JSON.parse(JSON.stringify({})) });
        callback && callback()
    }
}


export function getProject(item, callback) {
    return async function(dispatch, getState) {

        let singleProjectResponse = await apiClient.get(process.env.REACT_APP_MITIGATION_API_ENDPOINT + '/api/projects/' + item.id);

        callback && callback(singleProjectResponse.data || {})
        dispatch({ type: 'CURRENT_PROJECT', payload: singleProjectResponse.data || {} });
    }
}

export function putProject(payload, callback) {
    return async function (dispatch, getState) {
        
        dispatch({ type: 'APPLICATION_LOADING_STATE', payload: true });


        // New to save date in the format mysql database can store
        if (payload.declaration_date) {
            payload.declaration_date = moment(payload.declaration_date).format("YYYY-MM-DD HH:mm:ss");
        }

        if (payload.project_start_date) {
            payload.project_start_date = moment(payload.project_start_date).format("YYYY-MM-DD HH:mm:ss");
        }

        if (payload.project_end_date) {
            payload.project_end_date = moment(payload.project_end_date).format("YYYY-MM-DD HH:mm:ss");
        }

        if (payload.close_out_date) {
            payload.close_out_date = moment(payload.close_out_date).format("YYYY-MM-DD HH:mm:ss");
        }

        if (payload.latitude === "") {
            payload.latitude = null;
        }

        if (payload.longitude === "") {
            payload.longitude = null;
        }

        console.log(payload)

        apiClient.put(process.env.REACT_APP_MITIGATION_API_ENDPOINT + "/api/projects", payload, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((putResponse) => {
            callback();
            dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Project saved succesfully' } }))

        }).catch((error) => {
            callback(error);
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error saving project' } }))
            dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
        });

    }
}

export function deleteProject(callback) {
    return async function (dispatch, getState) {
        dispatch({ type: 'APPLICATION_LOADING_STATE', payload: true});
        const { currentProject } = getState().rootReducer;

        try {
            await apiClient.delete(process.env.REACT_APP_MITIGATION_API_ENDPOINT + '/api/projects/delete/' + currentProject.id);

            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Project deleted succesfully' } }))

        } catch (error) {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error occured while deleting the entry' } }))
            callback(error);
        }
       
        callback && callback()
        dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
    }
}