import React, { Suspense } from 'react';
import {ApplicationSpinner} from '@zawarski/palmetto-ui-components';
import axios from 'axios';
import { connect } from 'react-redux'
import { setDefaultInfo } from "../../redux/actions/index";
import Login from "./Login";
import LoginErrorMsg from "./LoginErrorMsg";

const AppComponent = React.lazy(() => import('../../App'));

const mapDispatchToProps = (dispatch) => {
    return {
        setDefaultInfo: (payload, magicUrl) => dispatch(setDefaultInfo(payload, magicUrl)),
    }
}

const mapStateToProps = state => {
    return {
        appLoading: state.rootReducer.appLoading,
    };
};


const withPalmettoAuth = PassedComponent => {
    const Wrapper = props => (
        class WithPalmettoAuth extends React.Component {

            state = {
                loginSuccessful: false,
                errorOccured: false,
                errorMessage: "",
            }

            async processLogin() {
                if (sessionStorage.getItem("userAuthCreds")) {

                    var token = JSON.parse(sessionStorage.getItem("userAuthCreds"));

                    var filter = {
                        "include":
                            [
                                {
                                    "relation": "account2positions",
                                    "scope": {
                                        "include": {
                                            "relation": "positions"
                                        }
                                    }
                                },
                                {
                                    "relation": "account2groups",
                                    "scope": {
                                        "include": [
                                            {
                                                "relation": "groups",
                                            },
                                            {
                                                "relation": "parentGroups",
                                            }
                                        ]

                                    }
                                }
                            ]
                    };

                    // Get palmettoUserAccount info

                    let userAccountObj = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/" + token.userId + "?access_token=" + token.id + "&filter=" + JSON.stringify(filter))


                    let settingsFilter = {
                        where: {
                            and: [
                                {
                                    'pvSettingType': 'selectedGroup'
                                },
                                {
                                    'pvAccountID': token.userId
                                }
                            ]
                        }
                    }


                    let settingsObj = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/settings?access_token=" + token.id + "&filter=" + JSON.stringify(settingsFilter))

                    let userType = 'COUNTY_USER';
                    let selectedCountyObj = {};
                    if(settingsObj && settingsObj.data && settingsObj.data.length) {
                        
                        let selectedGroupID =  settingsObj.data[0].pvSettingValue
                        userType = (+settingsObj.data[0].pvSettingValue) === 1 ? 'STATE_USER' : 'COUNTY_USER';
                        if(userAccountObj.data) {
                            userAccountObj.data.account2groups.forEach( (group) => {
                                if (group.groups && (+group.pvGroupID) === (+selectedGroupID)) {
                                    // group.groups is an object. Its added to know whether the account2group related is removed or not
                                    selectedCountyObj = group.groups;
                                }   
                            })
                        }

                    } else {
                        // if the setting is not present
                        if (userAccountObj.data) {
                            selectedCountyObj = userAccountObj.data.account2groups[0].groups;

                            userType = (+selectedCountyObj.pvGroupID) === 1 ? 'STATE_USER' : 'COUNTY_USER';
                        }
                    }

                    // Setting axios defaults for global use
                    axios.defaults.headers.common['Authorization'] = token.id;

                    if ((parseInt(selectedCountyObj.pvGroupID) >= 1 && parseInt(selectedCountyObj.pvGroupID) <= 47)) {
                        this.props.setDefaultInfo({
                            'palmettoUserAccount': (userAccountObj && userAccountObj.data) || [],
                            'selectedCounty': selectedCountyObj,
                            'userType': userType,
                            'creds': token
                        });
                        this.setState({ "loginSuccessful": true });
                    } else {
                        this.setState({
                            "errorOccured": true
                        });
                        this.setState({
                            "errorMessage": "Your active group dont have access to this system"
                        });
                    }

                }
            }

            async componentDidMount() {
                // probaby check here if token exists in local storage or not

                // This can probably go into Redux. Keep it here for now. If we use amplify's `withAuthenticator` HOC we wont need that anyways.
                this.processLogin();
            }

            constructor(props, context) {
                super(props, context);
                this.processLogin = this.processLogin.bind(this);
            }

            render() {

                const Loading = () => {
                    return (
                        <div className="layout vertical vertical-center full-height">
                            <ApplicationSpinner />
                            <div className="height-20"></div>
                            <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading the app</span>
                        </div>
                    )
                }                
                return (
                    <>
                        {
                            this.state.loginSuccessful ? 
                                <Suspense fallback={<Loading />}>
                                    <AppComponent {...this.props} />
                                </Suspense>
                            : 
                            this.state.errorOccured ? 
                            <LoginErrorMsg error={this.state.errorOccured} message={this.state.errorMessage}  {...this.props} /> : <Login processLogin={this.processLogin} />
                        }
                    </>

                )
            }
        }

    )

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper());
}

export { withPalmettoAuth }


