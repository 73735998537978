// import { getToken } from "./auth";
import { apiClient } from '../../common/axios_instance'; 

import { getAllProjectsBasic } from './projects';

export function setDefaultInfo(payload) {
    return async function(dispatch, getState) {
        if(payload.userType === 'COUNTY_USER') {
            try {
                let countyResponse = await apiClient.get(process.env.REACT_APP_MITIGATION_API_ENDPOINT + '/api/county/info/' + payload.selectedCounty.pvGroupName);
    
                dispatch({ type: 'CURRENT_MITIGATION_GROUP', payload: countyResponse.data || {} });
                dispatch({ type: 'MAP_LAT_LNG', payload: [countyResponse.data.latitude, countyResponse.data.longitude] });
                
            } catch(e) {
                return;
                // throw error page
            }
        } else {
            dispatch({ type: 'MAP_LAT_LNG', payload: [34.0, -81.0] });
        }

        try {

            // get mitigation_notes settings

            let filter = { 
                where: {
                    and: [
                        {
                            pvSettingType: 'mitigation-state-notes'
                        },
                        {
                            pvGlobalSetting: '1'
                        },
                    ] 
                }
            }

            let settingsResponse = await apiClient.get(process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?filter=' + JSON.stringify(filter));

            if (settingsResponse && settingsResponse.data && settingsResponse.data.length) {
                dispatch({ type: 'SET_MITIGATION_NOTES_SETTING', payload: settingsResponse.data[0] });
                dispatch({ type: 'SET_MITIGATION_NOTES_VALUE', payload: settingsResponse.data[0].pvSettingValue });
            } else {
                // Create the setting
                let body = {
                    pvSettingType: 'mitigation-state-notes',
                    pvGlobalSetting: '1',
                    pvAccountID: payload.palmettoUserAccount.id,
                }
                let response = await apiClient.put(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/settings", body, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                dispatch({ type: 'SET_MITIGATION_NOTES_SETTING', payload: response.data });
                dispatch({ type: 'SET_MITIGATION_NOTES_VALUE', payload: response.data.pvSettingValue });
            }
        } catch(e) {
            console.log('Error occured in settings')
        }

        dispatch({ type: 'SET_USER_TYPE', payload: payload.userType });
        dispatch({ type: 'SET_PALMETTO_SELECTED_GROUP', payload: payload.selectedCounty });
        dispatch({ type: 'SET_PALMETTO_SELECTED_GROUP_NAME', payload: payload.selectedCounty.pvGroupName });
        dispatch({ type: 'PALMETTO_USER_ACCOUNT', payload: payload.palmettoUserAccount });
        dispatch({ type: 'HEADER_TITLE_SMALL', payload: payload.palmettoUserAccount.username + ' 🞄 ' +
        (payload.userType === 'STATE_USER' ? 'State' : payload.selectedCounty.pvGroupName) });
        dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
    }
}

export const getMarkers = (callback) => {
    return async function(dispatch, getState) {

        let { palmettoUserAccount, selectedGroup} = getState().rootReducer;

        // Get the setting saved
        let filter = {
            "where": {
                "and": [{
                    "pvSettingType": "mitigation_projectSavedSearch",
                    "pvGroupID": selectedGroup,
                    "pvAccountID": palmettoUserAccount.id
                }]
            }
        }
        
        let settingsResponse = await apiClient.get(process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?filter=' + JSON.stringify(filter));

        let fieldsToSelect = [
            'id', 
            'latitude', 
            'longitude', 
            'project_type', 
            'grant_number',
            'project_title',
            'applicant_name',
            'project_type',
            'hazard_mitigated',
            'funding_source',
            'county',
            'primary_lifeline_mitigated',
        ]

        let params = `fields=${fieldsToSelect.join(',')}&`;

        // Build query parameter based on saved setting

        if (settingsResponse && settingsResponse.data && settingsResponse.data.length) {
            let value = JSON.parse(settingsResponse.data[0].pvSettingValue);
            value.forEach((item) => {
                if(item.checked) {
                    params += "filters[search]=" + item.value + "&";
                }
            }) 
        }

        if(params) {
            // remove the last `&` character
            let arr = params.split('');
            arr.length = arr.length - 1;
            params = arr.join('');
        }


        let projects = [];

        try {
            let projectsData  = await getAllProjectsBasic(params);
            projects = projectsData.dataset || [];
            console.log(projects)
            
            let arr = []

            projects.forEach( (project) => {
                if(project.latitude && project.longitude) {
                    arr.push(project);
                }
            })

            callback(arr);

        } catch(e) {
            console.log('error occured', e)
            callback([])
        }


    }
}

export function saveNotes(notes) {
    return async function(dispatch, getState) {
        
        const { notesSetting, notesValue } = getState().rootReducer;

        if (!notesSetting.pvSettingID) {
            console.error('Setting not created');
            return;
        }

        // If the notes havent changed, dont make a request
        if (notes === notesValue) {
            return;
        }


        try {

            let body = {
                pvSettingID: notesSetting.pvSettingID,
                pvSettingType: 'mitigation-state-notes',
                pvSettingValue: notes
            }
            await apiClient.put(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/settings", body, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })


            dispatch({ type: 'SET_MITIGATION_NOTES_VALUE', payload: notes });

        } catch(e) {

        }
    }
}

// This function saves map latlng and map zoom level

export function saveMapLatLngZoom(payload) {
    return function(dispatch) {
        payload.mapLatLng && dispatch({ type: 'MAP_LAT_LNG', payload: payload.mapLatLng });
        payload.mapZoom && dispatch({ type: 'MAP_ZOOM', payload: payload.mapZoom });
    }
}