import { withPalmettoAuth } from "./components/auth/PalmettoAuth";
import { datadogLogs } from '@datadog/browser-logs';
datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    datacenter: 'us',
    env: process.env.REACT_APP_DATADOG_STAGING_ENV,
    forwardErrorsToLogs: false,
    sampleRate: 100
})


export default withPalmettoAuth();
