/**
 * Contains all possible types for actions. This is also provides a single source of truth for all possible actions
 */

// Maps Action names to the variables that they change for the root reducer
export const ALL_TYPES = {
    'APPLICATION_LOADING_STATE': 'appLoading', // App Loading State
    'GRID_LOADING_STATE': 'gridLoadingState',
    'ALL_GROUPS': 'allGroups',
    'SET_LOGIN_STATE': 'loginState', // true if user is logged in
    'SET_APP_TYPE': 'appType', // Sets the App Type
    'SET_USER_TYPE': 'userType', // Type of user  (state, county)
    'SET_USER_SETTINGS': 'userSettings', // true if user is logged in
    'SET_PALMETTO_SELECTED_GROUP': 'selectedPalmettoGroup', // User Selected Group ID
    'SET_PALMETTO_SELECTED_GROUP_NAME': 'selectedPalmettoGroupName', // User Selected Group Name
    'SET_HEALTH': 'health', // true if the connection is still active and healthy
    'PALMETTO_USER_ACCOUNT': 'palmettoUserAccount', // basic user information from palmetto
    'ALL_MARKERS': 'markers', // Markers displayed on the map depending on userType
    'ALL_PROJECTS': 'allProjects', // contains all projects
    'CURRENT_PROJECT': 'currentProject',  // contains currently viewed project information
    'ALL_PLANS': 'allPlans', // contains all plans
    'SELECTED_COUNTY_FOR_PLAN': 'selectedCountyForPlan', // for scemd users, we wanna record which county was selected
    'PLANS_FOR_SELECTED_COUNTY': 'plansForSelectedCounty', // contanins all plans for a selected county
    'CURRENT_PLAN': 'currentPlan', // current plan being viewed
    'CURRENT_MITIGATION_GROUP': 'currentMitigationGroup', // Mitigation group object for the county user
    'MAP_LAT_LNG': 'mapLatLng', // Current latlng coords of the map
    'MAP_ZOOM': 'mapZoom', // Current map zoom level,
    'HEADER_TITLE_SMALL': 'headerTitleSmall', // small title in the header
    'SET_MITIGATION_NOTES_SETTING': 'notesSetting', // Current notes setting
    'SET_MITIGATION_NOTES_VALUE': 'notesValue', // Current notes value

};
