import React from 'react';
import ReactDOM from 'react-dom';
import AppShell from './AppShell';
import store from './redux/store/store.js';
import { Provider } from 'react-redux';
import Toast from './components/utilities/Toast';

// import * as serviceWorker from './serviceWorker';

// import { SW_INIT, SW_UPDATE } from "./redux/constants/action-types";
// window.location = "/"

ReactDOM.render(
  <>
    {/* <ScriptsLoader /> */}
    <Provider store={store}>
      <AppShell />
      <Toast />
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: (registration) => {
//     store.dispatch({ type: SW_UPDATE, payload: registration })
//   },
// });
