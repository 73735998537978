import axios from 'axios';

export const apiClient = axios.create()

apiClient.interceptors.request.use((config) => ({
    ...config,
    'params': {
        'access_token': sessionStorage.getItem("userAuthCreds") && JSON.parse(sessionStorage.getItem("userAuthCreds")).id,
        ...config.params
    },
    'headers': {
        'Authorization': sessionStorage.getItem("userAuthCreds") && JSON.parse(sessionStorage.getItem("userAuthCreds")).id
    }
}))    
